import React, { useContext } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { longNumber } from '../../../common/numbers'
import ViewsIcon from '../../../images/svg/views.svg'
import { Locations, MixpanelContext, AppUIEvent } from '../../../common/tracking'

interface ComponentProps{
 id: string
 name:string
 url: string
 stickerUrl:string
 backgroundUrl: string
 backgroundColor: string
 views:number
 tags:Array<string>
}

const StickerContainer = styled.div`
	position: relative;
	width: 100%;
	height: 335px;  
	background-color:#DE8CE8;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
`

const StickerImage = styled.img`
	position: absolute;
	top:0px;
	left:0px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	width: 100%;
	height: auto; 
	object-fit: cover;
	height: 338px;
	overflow: hidden;
`

const BackgroundImage = styled.img`
	position: absolute;
	top:0px;
	left:0px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	width: 100%;
	height: auto;
	max-height: 338px;
	overflow: hidden;
	object-fit: cover;
`

const StickerInfo = styled.div`
	width: 100%;
	height: 79px;  
`

const TopLine = styled.div`
	/* width: 100%; */
	height: 30px;  
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 16px 5px 16px;
`

const Title = styled.div`
	width: 70%;
	/* height: 79px;   */
	font-family: DM Sans;
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	line-height: 27px;
	/* display: flex; */
	text-decoration: none !important;
	color:var(--gray1);
	white-space: nowrap;
  overflow: hidden;
	text-overflow: ellipsis;
	
`

const ViewContainer = styled.div`
	width: 20%;
	margin: 5px 0px 0px 0px;
	display: flex;
	flex-direction: row;
  justify-content: end;
	align-items: center;
	/* border:1px solid red; */
	height: 20px;
`

const Tags = styled.div`
	margin: 15px 16px 10px 16px;
	align-items: center;
	font-size: 14px;
	color:var(--gray2);
	white-space: nowrap;
  overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
`

const Background = styled.div`
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	width: 100%;
	/* height: 100%; */
	height: 338px;
	overflow: hidden;
`

const StyledStickerLI = styled.li`
	position: relative;
	width: 335px;
	height: 417px;
	margin-bottom: 32px;
	background: #FFFFFF;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
  :active { 
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.12), 
			inset 0 0 0 1px rgba(0, 0, 0, 0.20); /* All the borders by using the spread properties */
	}
`

export const ViewDataIcon = styled.div`
	width:24px;
	height: 24px;
	margin: 0;
`

export const ViewData = styled.div`
	margin: 0 0 0 3px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 27px;
	text-decoration: none !important;
	color:var(--gray1);
`

const StickyInListView = (props:ComponentProps): JSX.Element => {
	const {
		name, stickerUrl, url, backgroundUrl = '', backgroundColor = '', views, id, tags,
	} = props

	const mixpanel = useContext(MixpanelContext)
	// console.log(`props=${JSON.stringify(props)}`)

	return (
		<StyledStickerLI onClick={() => {
			mixpanel.track(AppUIEvent.navigationStickieSelected, {
				location: Locations.home,
				id,
			})

			navigate(`${new URL(url).pathname}?showback`)
		}}
		>
			{/* <Link
				to={url}
				onClick={() => {
					mixpanel.track('Navigation: Stickie Selected', {
						location: 'Home',
						id,
					})
				}}
			> */}
			<StickerContainer>
				<Background style={{ backgroundColor: backgroundColor.length > 0 ? backgroundColor : 'transparent' }}>
					{backgroundUrl.length > 0 && <BackgroundImage src={backgroundUrl} alt="" />}
					<StickerImage src={stickerUrl} alt="" />
				</Background>
			</StickerContainer>
			{/* </Link> */}
			<StickerInfo>
				<TopLine>
					<Title>
						{name}
					</Title>
					<ViewContainer>
						<ViewDataIcon><ViewsIcon width={24} height={24} /></ViewDataIcon>
						<ViewData>{longNumber(views)}</ViewData>
					</ViewContainer>
				</TopLine>
				<Tags>
					{tags.map((tag) => `#${tag.toLowerCase()}`).join(' ')}
				</Tags>
			</StickerInfo>

		</StyledStickerLI>

	)
}

export default StickyInListView
