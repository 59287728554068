
import { firestore } from 'firebase-admin'
import firebase from 'gatsby-plugin-firebase'
import { useEffect, useState } from 'react'
import { take } from 'lodash'

interface StickiesLight {
	id: string
	name: string
	url: string
	stickerUrl:string
	backgroundUrl: string
	backgroundColor: string
	views:number
	cdnStickerWithBkgUrl:string
	tags:Array<string>
}

interface HookOutput {
	loading: boolean
	items: Array<StickiesLight>
	hasNextPage: boolean
	error: Error | undefined
	loadMore:VoidFunction
}

const mapData = (doc:firestore.DocumentData):StickiesLight => {
	const {
		url, name, views, stickerUrl, id, backgroundUrl, backgroundColor, cdnStickerWithBkgUrl, tags = [],
	} = doc.data()
	const newUrl = process.env.NODE_ENV !== 'production' ? url.replace('https://stickies.fun', 'http://localhost:8000') : url
	return {
		url: newUrl,
		views,
		name,
		stickerUrl,
		backgroundUrl,
		backgroundColor,
		cdnStickerWithBkgUrl,
		id,
		tags,
	}
}

export default function useLoadStickies(pageSize:number):HookOutput {
	const [items, setItems] = useState<Array<StickiesLight>>(new Array<StickiesLight>())
	const [hasNextPage, setHasNextPage] = useState<boolean>(false)
	const [lastVisible, setLastVisible] = useState<firestore.DocumentData | null>(null)
	const [loading, setLoading] = useState<any>(false)
	const [error, setError] = useState<Error | undefined>()

	const COLLECTION = 'readOnlyStickies'

	const pageSizeWithPad = pageSize + 1
	const loadMore = ():void => {
		const db = firebase.firestore()
		setLoading(true)

		const next = db.collection(COLLECTION)
			.where('status', '==', 'published')
			.orderBy('views', 'desc')
			.orderBy('name')
			.startAfter(lastVisible)
			.limit(pageSizeWithPad)

		next.get().then((documentSnapshots) => {
			// Get the last visible document
			setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 2])
			setHasNextPage(documentSnapshots.docs.length > pageSize)
			const newItems = take(documentSnapshots.docs.map((doc) => mapData(doc)), pageSize)

			setItems([...items, ...newItems])
		}).catch((err) => {
			setError(err)
		}).finally(() => {
			setLoading(false)
		})
	}

	useEffect(():any => {
		const db = firebase.firestore()
		const first = db.collection(COLLECTION)
			.where('status', '==', 'published')
			.orderBy('views', 'desc')
			.orderBy('name')
			.limit(pageSizeWithPad)
		setLoading(true)
		return first.get().then((documentSnapshots) => {
		// Get the last visible document
			setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 2])
			setHasNextPage(documentSnapshots.docs.length > pageSize)
			setItems(take(documentSnapshots.docs.map((doc) => mapData(doc)), pageSize))
		}).catch((err) => {
			console.log(err)
			setError(err)
		}).finally(() => {
			setLoading(false)
		})
	}, [])

	return {
		loadMore,
		items,
		hasNextPage,
		loading,
		error,
	}
}
