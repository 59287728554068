import React, {
	useRef, useEffect, useContext,
} from 'react'
import Helmet from 'react-helmet'
import styled, { createGlobalStyle } from 'styled-components'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useWindowHeight } from '@react-hook/window-size'
import StickyInListView from '../components/Home/ListViewOfStickies'
import SmartLayout from '../layouts/smartLayout'
import { siteData } from '../common/siteData'
import logoStickies from '../images/stickies_brand.png'
import TwitterSummaryCard from '../components/share/TwitterCards/Summary'
import { MixpanelContext } from '../common/tracking'
import { device, desktopDemoDevice } from '../utils/device'
import useLoadStickies from '../hooks/useLoadStickies'

const GlobalStyle = createGlobalStyle`
	body {
		background-color: var(--brandColor70Solid);
	}

	@media ${device.isTabletOrLarger} { 
		body {
			background-color: var(--gray4);
		}
	}
`

const StyledSite = styled.div`
	-webkit-tap-highlight-color:rgba(0,0,0,0);
  width: 100%;
	height: 100%;
  font-family: 'DM Sans';
  font-weight: normal;
  display: flex;
  flex-direction: column;
 
	::-webkit-scrollbar { 
    display: none;  /* Chrome Safari */
	}
	scroll-behavior: smooth;
	background-color: transparent;
	position: relative;
	overflow-x: hidden;

	@media ${device.isTabletOrLarger} { 
		background-color: var(--brandColor70Solid);
	}

`

const StyledBody = styled.div`
	width: 100%;
	display: flex;
  flex-direction: column;
	align-items: center;
`

const StyledStickerUL = styled.ul`
	display: flex;
	flex-direction: column;
	
	align-items: center;
	margin-top:16px;
  list-style-type: none;
  padding-inline-start: 0px;
	width: 100%;
`
const StyledPopularStickers = styled.div`
	background-color: inherit;
	height: auto;
	width: 100%;
	padding: 6px 0 0 0;
`

const StyleTopHalf = styled.div<{height:number}>`
	width: 100vw;
	height: ${(props) => Math.round(props.height * 0.95)}px;
	display: flex;
  flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;
	@media ${device.isTabletOrLarger} { 
		width: calc(${desktopDemoDevice.width}px);
		height: calc(${Math.round(desktopDemoDevice.height * 0.95)}px);
	}
`
/*
const StyledVideoPlayer = styled.video`
	box-sizing: content-box;
	min-height: 100%;
  min-width: 100%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
`
*/

const InsetVideo = styled.div`
	width: 95%;
	height: 90%;
	box-shadow: rgb(0 0 0 / 16%) 0px 2px 4px;
	position: relative;
	overflow-x: hidden;
	overflow-y: hidden;
	box-sizing: content-box;
	padding: 0px;
	margin: 0px;
	border: 0px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	z-index: 1;

	video {
		box-sizing: content-box;
		min-height: 100%;
		min-width: 100%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
`

const StyledLogo2 = styled.img`
  width: auto;
	height: 32px;
`

export const StyledHomePageHeader = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  background-color: transparent;
  z-index: 300000;
	display: flex;
  flex-direction: column;
  justify-content: center;
	align-items: center;
	height: 72px;
`

const mainVideo = '/media/video/stickies_intro_1.2.mp4'

const isSafari = ():boolean => {
	const ua = navigator.userAgent.toLowerCase()
	return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0
}

const Player:React.FC = () => {
	const videoParentRef = useRef()
	// const [shouldUseImage, setShouldUseImage] = useState(false)
	useEffect(() => {
		// check if user agent is safari and we have the ref to the container <div />
		if (isSafari() && videoParentRef.current) {
			// obtain reference to the video element
			const player = videoParentRef.current.children[0]

			// if the reference to video player has been obtained
			if (player) {
				// set the video attributes using javascript as per the
				// webkit Policy
				player.controls = false
				player.playsinline = true
				player.muted = true
				player.setAttribute('muted', '') // leave no stones unturned :)
				player.autoplay = true

				// Let's wait for an event loop tick and be async.
				setTimeout(() => {
					// player.play() might return a promise but it's not guaranteed crossbrowser.
					const promise = player.play()
					// let's play safe to ensure that if we do have a promise
					if (promise.then) {
						promise
							.then(() => {})
							.catch(() => {
								// if promise fails, hide the video and fallback to <img> tag
								videoParentRef.current.style.display = 'none'
								// setShouldUseImage(true)
							})
					}
				}, 0)
			}
		}
	}, [])

	return (
		<InsetVideo
			ref={videoParentRef}
			dangerouslySetInnerHTML={{
				__html: `
				<video
					loop
          muted
          autoplay
          playsinline
					preload="auto"
        >
        <source src="${mainVideo}" type="video/mp4" />
        </video>`,
			}}
		/>
	)
}

// const IndexPage = ({ data }:{data:any}):JSX.Element => {
const IndexPage = ():JSX.Element => {
	const pageSize = 10
	const mixpanel = useContext(MixpanelContext)
	const {
		loading, items, hasNextPage, error, loadMore,
	} = useLoadStickies(pageSize)

	const windowHeight = useWindowHeight({ initialHeight: 856 })

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage,
		onLoadMore: loadMore,
		// When there is an error, we stop infinite loading.
		// It can be reactivated by setting "error" state as undefined.
		disabled: !!error,
		// `rootMargin` is passed to `IntersectionObserver`.
		// We can use it to trigger 'onLoadMore' when the sentry comes near to become
		// visible, instead of becoming fully visible on the screen.
		rootMargin: '0px 0px 400px 0px',
	})

	useEffect(():void => {
		mixpanel.track('Visit: Home')
	}, [mixpanel])

	return (

		<>
			<Helmet>
				<title>{`${siteData.siteTitle}: ${siteData.siteDescription}`}</title>
				<meta property="og:url" content={siteData.rootUrl} />
				<meta property="og:title" content={siteData.siteTitle} />
				<meta property="og:description" content={siteData.siteDescription} />
				<meta property="og:image" content={siteData.ogImageUrl} />
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=0" />
			</Helmet>
			<TwitterSummaryCard imgUrl={siteData.twitterImageUrl} title={siteData.twitterTitle} description={siteData.twitterDescription} />
			<GlobalStyle />
			<SmartLayout>
				<StyledSite>
					<StyledHomePageHeader>
						<StyledLogo2 src={logoStickies} alt="" />
					</StyledHomePageHeader>
					<StyledBody>
						<StyleTopHalf height={windowHeight}>
							<Player />
						</StyleTopHalf>
						<StyledPopularStickers>
							<StyledStickerUL>
								{items.map((item) => (
									<StickyInListView
										key={item.id}
										{...item}
									/>
								))}
								{/*
          As long as we have a "next page", we show "Loading" right under the list.
          When it becomes visible on the screen, or it comes near, it triggers 'onLoadMore'.
          This is our "sentry".
          We can also use another "sentry" which is separated from the "Loading" component like:
            <div ref={sentryRef} />
            {loading && <ListItem>Loading...</ListItem>}
          and leave "Loading" without this ref.
      */}
								{items.length >= pageSize && <div ref={sentryRef} />}
								{(loading) && (
									<li>
										LOADING...
									</li>
								)}

							</StyledStickerUL>
						</StyledPopularStickers>
					</StyledBody>
				</StyledSite>
			</SmartLayout>
		</>

	)
}

export default IndexPage
