import React from 'react'
import Helmet from 'react-helmet'

interface ComponentProps {
  imgUrl:string
  description:string
  title: string
}

const TwitterSummaryCard = (props:ComponentProps): JSX.Element => {
	const { imgUrl, title, description } = props
	return (
		<Helmet>
			<meta name="twitter:app:name:iphone" content="Stickies" />
			<meta name="twitter:app:id:iphone" content="1569620768" />
			<meta name="twitter:creator" content="@stickiesfun" />
			<meta name="twitter:site" content="@stickiesfun" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:image" content={imgUrl} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
		</Helmet>
	)
}
export default React.memo(TwitterSummaryCard)
