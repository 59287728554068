export const longNumber = (value: number | undefined): string => {
	const str = `${value || 0}`
	let output = ''
	let i = str.length - 3
	while (i >= 0) {
		output = `${str.substring(i, i + 3)}${output ? ',' : ''}${output}`
		i -= 3
	}
	if (i > -3) {
		output = `${str.substring(i, 3 + i)}${output ? ',' : ''}${output}`
	}
	return output
}
